import api from 'api';

/**
 * Used to mark a User as "We already did this kind of marketing"
 * @param {String} params.unsplashId - User target
 * @param {String} params.markAs - Action that had been done
 */
const markAs = params => {
  return api.marketing.markAs(params);
};

const getBy = params => {
  return api.marketing.getBy(params).then(r => r.data);
};

const getUnsplashUsers = () => {
  return api.marketing.getUnsplashUsers().then(r => r.data);
};

const actionsMarketing = {
  markAs,
  getBy,
  getUnsplashUsers,
};

export default actionsMarketing;
