import React, { useState, useEffect, Fragment } from 'react';
import cloneDeep from 'lodash.clonedeep';
import nearestPoint from '@turf/nearest-point';
import { point as createPoint, featureCollection } from '@turf/helpers';
import actions from 'actions';
import Map from 'components/Map';
import Slider from 'components/Slider';
import SpotForm from './SpotForm';
import MainPhotoForm from './MainPhotoForm';
import TopPhotoForm from './TopPhotoForm';
import DescriptionForm from './DescriptionForm';
import config from 'config';
import './styles.scss';

const TRANSLATION_TARGETS = [
  // 'ca',
  'de', // this is actually totally done (confirmed)
  'es',
  'fr',
  // 'it',
  // 'ja',
  // 'ko',
  // 'nl',
  // 'pt',
  // 'pl',
  // 'ru',
  // 'tr',
  // 'vi',
  // 'zh',
];

const ACTIONS = {
  NONE: 'NONE',
  SHOW_SPOT: 'SHOW_SPOT',
  CREATE: {
    SET_GPS: 'CREATE__SET_GPS',
    SPOT_FORM: 'CREATE__SPOT_FORM',
  },
  ASSIGN: 'ASSIGN',
  EDIT: 'EDIT',
};

const PHOTO_TYPE = {
  UNSPLASH: 'UNSPLASH',
  USER: 'USER',
  DETECTIVE: 'DETECTIVE',
};

const REJECTION_CAUSES = [
  { value: 0, label: 'Wrong GPS', 'data-action-key': 2 },
  { value: 1, label: 'Bad Quality' },
  { value: 2, label: 'Drone' },
  { value: 3, label: 'Too many photos from that spot' },
  { value: 4, label: 'Private' },
  { value: 5, label: 'Selfie' },
  { value: 6, label: 'Temporal' },
  { value: 7, label: 'Elieser' },
  { value: 8, label: 'Not a Spot', 'data-action-key': 3 },
  { value: 9, label: 'Already Approved' },
  { value: 10, label: 'Not Interesting Enough', 'data-action-key': 4 },
  { value: 11, label: 'Not Centerd' },
  { value: 12, label: 'Too Paronamic' },
  { value: 13, label: 'Too Much Edited' },
  { value: 14, label: 'Border Too Big' },
  { value: 15, label: 'Watermark Too Big' },
  { value: 99, label: 'Unknown' },
];

const Moderation = () => {
  const [photos, setPhotos] = useState([]);
  const [spots, setSpots] = useState([]);
  const [cityTours, setCityTours] = useState([]);
  const [userPhotos, setUserPhotos] = useState([]);

  //
  const [pointsToShow, setPointsToShow] = useState([]);

  const [action, setAction] = useState(ACTIONS.NONE);

  // Data used to Create and Edit a Spot
  const [id, setId] = useState(null);
  const [name, setName] = useState('');
  const [from, setFrom] = useState('');
  const [slug, setSlug] = useState('');
  const [country, setCountry] = useState('');
  const [isGPSCorrect, setIsGPSCorrect] = useState(true);
  const [newGPS, setNewGPS] = useState({});

  // Data used to Assign photo to Spot
  const [assignTo, setAssignTo] = useState(null);

  // Data used to Reject
  const [cause, setCause] = useState('');

  // Data used for filters
  const [showPendings, setShowPendings] = useState(false);
  const [showPendingsFromUsers, setShowPendingsFromUsers] = useState(false);
  const [showRejecteds, setShowRejecteds] = useState(false);
  const [showSpots, setShowSpots] = useState(false);
  const [showNulls, setShowNulls] = useState(false);
  const [coors, setCoors] = useState({
    sw: {
      lat: 22,
      lng: -129,
    },
    ne: {
      lat: 50,
      lng: -66,
    },
  });
  const [isSettingSW, setIsSettingSW] = useState(false);
  const [isSettingNE, setIsSettingNE] = useState(false);

  // Others
  const [isCreating, setIsCreating] = useState(false);
  const [center, setCenter] = useState(undefined);
  const [zoom, setZoom] = useState(undefined);
  const [spotWithNoMainPhotoId, setSpotWithNoMainPhotoId] = useState(null);
  const [spotWithNoIsTop, setSpotWithNoIsTop] = useState(null);
  const [spotWithNoDescription, setSpotWithNoDescription] = useState(null);

  useEffect(() => {
    const _onKeyPress = e => {
      if (
        document.querySelector('.TopPhotoForm') ||
        document.querySelector('.MainPhotoForm') ||
        document.querySelector('.DescriptionForm')
      ) {
        return;
      }

      if (e.key === 'Escape') {
        const button = document.querySelector('button.reject');

        if (button) {
          button.click();
        }
      } else {
        const actionButton = document.querySelector(`[data-action-key="${e.key}"]`);
        // event.metaKey is CMD (CMD + 3, for example)
        if (actionButton && !e.metaKey && actionButton.click) {
          e.preventDefault();
          actionButton.click();
        }
      }
    };

    (async () => {
      const { data } = await fetch(
        'https://storage.googleapis.com/mari-a5cc7.appspot.com/cityTours/index-v1.json?rand=' +
          Math.random()
      ).then(r => r.json());

      setCityTours(
        data.cityTours.map(x => ({
          ...x,
          type: 'CITYTOUR',
          pointType: 'citytour',
          lat: x.location.lat,
          lng: x.location.lng,
        }))
      );
    })();

    document.addEventListener('keydown', _onKeyPress, false);
    return () => {
      document.removeEventListener('keydown', _onKeyPress, false);
    };
  }, []);

  const _getCloserPending = selectedPhoto => {
    const thePhotos = [
      ...photos.filter(x => x._status === 3),
      ...userPhotos.filter(x => x._status === 0),
    ]
      .filter(x => x.id !== selectedPhoto.id)
      .map(x => createPoint([x.lat, x.lng], { photo: x }));

    if (thePhotos.length === 0) {
      return null;
    }

    const photoPoints = featureCollection(thePhotos);

    const centerPoint = createPoint([selectedPhoto.lat, selectedPhoto.lng]);
    const closer = nearestPoint(centerPoint, photoPoints);
    return closer;
  };

  const _moveToNextPhoto = selectedPhoto => {
    if (!selectedPhoto) {
      reset();
      return;
    }

    // Get next photo pending
    const nextPhotoPoint = _getCloserPending(selectedPhoto);

    if (!nextPhotoPoint || !nextPhotoPoint.properties || !nextPhotoPoint.properties.photo) {
      reset();
      return;
    }

    const nextPhoto = nextPhotoPoint.properties.photo;

    // Simulate click on it
    _onPointClick(nextPhoto);

    // Move Map
    setCenter({
      lat: nextPhoto.lat,
      lng: nextPhoto.lng,
    });
  };

  const reset = (callback = () => {}) => {
    window.spots = spots;

    if (document.querySelector("button[title='Close']")) {
      document.querySelector("button[title='Close']").click();
    }

    setSpots(spots =>
      spots.map(x => {
        x.isSelected = false;
        return x;
      })
    );

    setPhotos(photos =>
      photos.map(x => {
        x.isSelected = false;
        return x;
      })
    );

    setUserPhotos(userPhotos =>
      userPhotos.map(x => {
        x.isSelected = false;
        return x;
      })
    );

    setId(null);
    setName('');
    setFrom('');
    setSlug('');
    setCountry('');
    setIsGPSCorrect(true);
    setNewGPS({});
    setAssignTo(null);
    setCause('');

    setAction(ACTIONS.NONE);

    if (typeof callback === 'function') {
      callback();
    }
  };

  const getUnsplashPhotosPending = async () => {
    const hydrateds = await actions.photos.getHydratedsWithLocation({
      limit: 10000,
      coors,
    });

    const photos = hydrateds
      .filter(
        x =>
          coors.ne.lat > x.location.position.latitude && coors.sw.lat < x.location.position.latitude
      )
      .map(x => {
        const photo = {
          ...x,
          lat: x.location.position.latitude,
          lng: x.location.position.longitude,
          isSelected: false,
          type: PHOTO_TYPE.UNSPLASH,
          pointType: 'photo',
        };

        return photo;
      });

    window.photos = photos;

    setPhotos(photos);
  };

  const getDetectivePhotosPending = async () => {
    const { photos: allPhotos } = await actions.photos.detective.getReadys();

    const photos = allPhotos.map(x => {
      const photo = {
        ...x,
        lat: x.location.position.latitude,
        lng: x.location.position.longitude,
        isSelected: false,
        type: PHOTO_TYPE.UNSPLASH,
        pointType: 'photo',
        detective: x.detective,
      };

      return photo;
    });

    window.photos = photos;

    setPhotos(photos);
  };

  const getUserPhotosPending = async () => {
    const hydrateds = await actions.userPhotos.getHydratedsWithLocation({
      limit: 10000,
    });

    const userPhotos = hydrateds.map(x => {
      const photo = {
        ...x,
        lat: x.location.lat,
        lng: x.location.lng,
        isSelected: false,
        type: PHOTO_TYPE.USER,
        pointType: 'userPhoto',
      };

      return photo;
    });

    window.userPhotos = userPhotos;

    setUserPhotos(userPhotos);
  };

  const getSpots = async () => {
    const spots = (await actions.spots.getAllLight()).map(x => ({
      ...x,
      isSelected: false,
      pointType: 'spot',
      // This is just to use from Developers Console
      goThere: () => {
        setCenter({ lat: x.lat, lng: x.lng });
      },
    }));

    window.spots = spots;

    setSpots(spots);

    setSpotWithNoMainPhotoId(spots.find(x => !x.mainPhotoId));
    setSpotWithNoDescription(
      spots.find(x => typeof x.description === 'undefined' || x.description === null)
    );

    // Request some translations
    let translated = 0;

    for (const spot of spots) {
      if (!spot.description || !spot.description.en) {
        continue;
      }

      const descriptionsReady = Object.keys(spot.description);

      const isSomeMissing = TRANSLATION_TARGETS.some(target => !descriptionsReady.includes(target));

      if (isSomeMissing) {
        setTimeout(() => {
          actions.spots.translateDescriptionById(spot.id);
        }, 1000 * translated);

        translated++;
      }

      if (translated > 150) {
        return;
      }
    }

    if (translated === 0) {
      window.alert('Lista la traducida retroactiva!');
    }
  };

  const getSelectedPhoto = () => {
    return cloneDeep(photos.find(x => x.isSelected) || userPhotos.find(x => x.isSelected));
  };

  const getSelectedSpot = () => {
    return cloneDeep(spots.find(x => x.isSelected));
  };

  const _onPointHover = photo => {
    if (photo.urls && photo.urls.regular) {
      new Image().src = photo.urls.regular;
    } else if (photo.url) {
      new Image().src = photo.url;
    } else {
      console.log('$$ photo with no hover', photo);
    }
  };

  const _onPointClick = photo => {
    console.info('Photo Clicked', photo);

    reset(() => {
      setNewGPS({ lat: photo.lat, lng: photo.lng });

      if (photo.type === PHOTO_TYPE.UNSPLASH) {
        setPhotos(photos => [
          ...photos.map(x => ({
            ...x,
            isSelected: x.id === photo.id,
          })),
        ]);

        // if (photo.location && photo.location.name) {
        //   setName(photo.location.name.split(',')[0]);
        // }
      } else if (photo.type === PHOTO_TYPE.USER) {
        setUserPhotos(userPhotos => [
          ...userPhotos.map(x => ({
            ...x,
            isSelected: x.id === photo.id,
          })),
        ]);

        setName((photo.name || '').trim());
        setFrom((photo.from || '').trim());
      } else if (photo.type === PHOTO_TYPE.DETECTIVE) {
        console.log('$$ NO ACTION DEFINED YET', photo);
      }
    });
  };

  const _onSpotClick = async spot => {
    let fullData = {};

    if (!spot.isHydrated) {
      const { spot: fullSpot } = await actions.spots.getHydratedById(spot.id);
      fullData = { ...fullSpot, isHydrated: true };
    }

    console.info('Spot Clicked', spot);

    if (action === ACTIONS.ASSIGN) {
      setAssignTo(spots.find(x => x.id === spot.id));
    } else {
      setAction(ACTIONS.SHOW_SPOT);
    }

    setSpots(spots =>
      spots.map(x => {
        const final = { ...x, isSelected: x.id === spot.id };

        if (x.id === spot.id && !spot.isHydrated) {
          Object.assign(final, fullData);
        }

        return final;
      })
    );

    setTimeout(() => {
      document.querySelector('.Map button.approve').focus();
    }, 0);
  };

  const _onMapChange = ({ zoom, center }) => {
    setZoom(zoom);
    setCenter(center);
  };

  const _onReject = () => {
    const photo = getSelectedPhoto();
    const nextPhotoToClick = cloneDeep(photo);

    if (photo.type === PHOTO_TYPE.UNSPLASH) {
      // Not necessary to wait here
      actions.photos.moderation.reject({
        id: photo.id,
        cause,
      });

      setPhotos(photos =>
        photos.map(x => (x.id === photo.id ? { ...x, _status: 6, isSelected: false } : x))
      );
    } else {
      const causeId = Number(cause);

      if (Number.isNaN(causeId)) {
        alert('Cause must be a number');
        return;
      }

      // Not necessary to wait here
      actions.userPhotos.reject({
        ...photo,
        cause: causeId,
      });

      setUserPhotos(userPhotos =>
        userPhotos.map(x => (x.id === photo.id ? { ...x, _status: 2, isSelected: false } : x))
      );
    }

    _moveToNextPhoto(nextPhotoToClick);
  };

  const _onAssign = async () => {
    setIsCreating(true);

    const photo = getSelectedPhoto();
    const nextPhotoToClick = cloneDeep(photo);
    const spot = getSelectedSpot();

    if (!spot.isHydrated) {
      window.alert('Falta hidratar');
      return;
    }

    const newPhoto = {
      ...photo,
      isSelected: false,
      _status: photo.type === PHOTO_TYPE.UNSPLASH ? 5 : 1,
    };

    let newSpot = {
      ...spot,
      isSelected: false,
    };

    if (photo.type === PHOTO_TYPE.UNSPLASH) {
      newSpot.photos = [...(spot.photos || []), photo.id];
      newSpot.photosHydrated = [...(spot.photosHydrated || []), photo];
    } else {
      newSpot.userPhotos = [...(spot.userPhotos || []), photo.id];
      newSpot.userPhotosHydrated = [...(spot.userPhotosHydrated || []), photo];
    }

    setPhotos(photos => photos.map(x => (x.id === photo.id ? newPhoto : x)));
    setUserPhotos(userPhotos => userPhotos.map(x => (x.id === photo.id ? newPhoto : x)));

    setSpots(spots => spots.map(x => (x.id === spot.id ? newSpot : x)));
    setSpotWithNoMainPhotoId(newSpot);

    _moveToNextPhoto(nextPhotoToClick);

    await actions.spots.assign(spot, newPhoto);

    // This can be async
    actions.spots.translateDescriptionById(spot.id);

    let proms = [];
    if (photo.type === PHOTO_TYPE.UNSPLASH) {
      proms.push(actions.photos.moderation.approve(photo));
    } else {
      proms.push(actions.userPhotos.approve(photo, spot));
    }

    Promise.all(proms)
      .then(() => {
        //
      })
      .catch(error => {
        window.alert('Error en assign!: ' + error.message);
        console.log('$$ error', error);
      })
      .finally(() => {
        setIsCreating(false);
      });
  };

  const _onSpotFormChange = data => {
    setName(data.name);
    setFrom(data.from);
    setSlug(data.slug);
    setCountry(data.country);
  };

  const _onCreateNewSpotClick = () => {
    setAction(ACTIONS.CREATE.SET_GPS);
    setIsGPSCorrect(false);
  };

  const _onRejectDrone = () => {
    setCause('Drone');
    _onReject();
  };

  const _onRejectBlah = () => {
    setCause('Blah');
    _onReject();
  };

  const _onRejectWrongGPS = () => {
    setCause('Wrong GPS');
    _onReject();
  };

  const _onGPSSet = () => {
    setAction(ACTIONS.CREATE.SPOT_FORM);
    setIsGPSCorrect(false);
  };

  const _onCreateSpotClick = () => {
    const photo = getSelectedPhoto();
    const nextPhotoToClick = cloneDeep(photo);

    if (!country) {
      window.alert('Missing country');
      return;
    }

    if (!slug) {
      window.alert('Missing slug');
      return;
    }

    setIsCreating(true);

    let spot = {
      id: null,
      name,
      from,
      slug,
      country,
      lat: null,
      lng: null,
      photos: [],
      userPhotos: [],
      isTop: false,
      mainPhotoId: photo.id,
    };

    if (photo.type === PHOTO_TYPE.UNSPLASH) {
      spot.photos = [photo.id];
    } else {
      spot.userPhotos = [photo.id];
    }

    if (isGPSCorrect) {
      if (photo.type === PHOTO_TYPE.UNSPLASH) {
        spot.lat = photo.location.position.latitude;
        spot.lng = photo.location.position.longitude;
      } else {
        spot.lat = photo.location.lat;
        spot.lng = photo.location.lng;
      }
    } else {
      spot.lat = newGPS.lat;
      spot.lng = newGPS.lng;
    }

    actions.spots
      .create(spot)
      .then(newSpot => {
        if (photo.type === PHOTO_TYPE.UNSPLASH) {
          newSpot.photosHydrated = [photo];
          newSpot.userPhotosHydrated = [];
          actions.photos.moderation.approve(photo).catch(err => {
            console.log('$$ actions.photos.moderation.approve error', err);
            window.alert('Error Approving unsplash photo: ' + err.message);
          });
        } else {
          newSpot.photosHydrated = [];
          newSpot.userPhotosHydrated = [photo];
          actions.userPhotos.approve(photo, newSpot).catch(err => {
            console.log('$$ userPhotos.approve error', err);
            window.alert('Error Approving user photo and sending notification: ' + err.message);
          });
        }

        newSpot.isSelected = false;
        newSpot.pointType = 'spot';

        setSpots(spots => [...spots, newSpot]);

        if (photo.type === PHOTO_TYPE.UNSPLASH) {
          setPhotos(photos => [
            ...photos.map(x => (x.id === photo.id ? { ...x, _status: 5, isSelected: false } : x)),
          ]);
        }

        if (photo.type === PHOTO_TYPE.USER) {
          setUserPhotos(userPhotos => [
            ...userPhotos.map(x =>
              x.id === photo.id ? { ...x, _status: 1, isSelected: false } : x
            ),
          ]);
        }

        setSpotWithNoIsTop(newSpot);
        setSpotWithNoDescription(newSpot);

        _moveToNextPhoto(nextPhotoToClick);
      })
      .catch(error => {
        window.alert('Error!: ' + error.message);
        console.log('$$ error', error, spot, photo);
      })
      .finally(() => {
        setIsCreating(false);
      });
  };

  const _onMapClick = ({ lat, lng, ...other }) => {
    if (action === ACTIONS.CREATE.SET_GPS || action === ACTIONS.EDIT) {
      setNewGPS({ lat, lng });
    } else if (isSettingSW) {
      setCoors({
        ...coors,
        sw: {
          lat,
          lng,
        },
      });

      setIsSettingNE(false);
      setIsSettingSW(false);
    } else if (isSettingNE) {
      setCoors({
        ...coors,
        ne: {
          lat,
          lng,
        },
      });

      setIsSettingNE(false);
      setIsSettingSW(false);
    }
  };

  useEffect(() => {
    const pointsToShow = [...cityTours];

    if (showPendings) {
      if (showNulls) {
        pointsToShow.push(...photos.filter(x => x._status === 3));
      } else {
        pointsToShow.push(...photos.filter(x => x.lat !== null && x._status === 3));
      }
    }

    if (showPendingsFromUsers) {
      pointsToShow.push(...userPhotos.filter(x => x._status === 0));
    }

    if (showRejecteds) {
      pointsToShow.push(...userPhotos.filter(x => x._status === 2));
    }

    if (showSpots) {
      pointsToShow.push(...spots);
    }

    const isSettingGPS = action === ACTIONS.CREATE.SET_GPS;
    const isCreatingSpotWithWrongGPS = !isGPSCorrect && action === ACTIONS.CREATE.SPOT_FORM;
    const isEditing = action === ACTIONS.EDIT;

    if (isSettingGPS || isCreatingSpotWithWrongGPS || isEditing) {
      pointsToShow.push({ ...newGPS, pointType: 'crosshair' });
    }

    setPointsToShow(pointsToShow);
  }, [
    action,
    spots,
    userPhotos,
    photos,
    showPendings,
    showSpots,
    showNulls,
    showPendingsFromUsers,
    showRejecteds,
    isGPSCorrect,
    newGPS,
    cityTours,
  ]);

  const onPendingsFilterClick = async () => {
    if (photos.length === 0) {
      await getUnsplashPhotosPending();
    }

    setShowPendings(!showPendings);
  };

  const onPendingsFromUsersFilterClick = async () => {
    if (userPhotos.length === 0) {
      await getUserPhotosPending();
    }

    setShowPendingsFromUsers(!showPendingsFromUsers);
  };

  const onDetectiveFilterClick = async () => {
    if (photos.length === 0) {
      await getDetectivePhotosPending();
    }

    setShowPendings(!showPendings);
  };

  // const onRejectedsFilterClick = async () => {
  //   if (photos.length === 0) {
  //     await getUnsplashPhotosPending();
  //   }

  //   setShowRejecteds(!showRejecteds);
  // };

  const onSpotsFilterClick = async () => {
    if (spots.length < 20) {
      await getSpots();
    }

    setShowSpots(!showSpots);
  };

  const onCoorsChange = newData => {
    setCoors({ ...coors, ...newData });
    setPhotos([]);
  };

  const _onEditSpotClick = () => {
    const spot = getSelectedSpot();

    setId(spot.id);
    setName(spot.name);
    setFrom(spot.from);
    setSlug(spot.slug);
    setCountry(spot.country);
    setIsGPSCorrect(false);
    setNewGPS({
      lat: spot.lat,
      lng: spot.lng,
    });
    setAction(ACTIONS.EDIT);
  };

  const _onCreateCityToutClick = async () => {
    const { lat, lng } = center;

    const r = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&result_type=locality&language=en&key=${config.googleMaps.apiKey}`
    );
    const json = await r.json();
    const parts = json.results[json.results.length - 1].address_components;

    const locality = (parts.find(x => x.types.includes('locality')) || parts[0]).long_name;
    const country = parts.find(x => x.types.includes('country')).long_name;

    const city = `${locality}, ${country}`;

    if (!city) {
      window.alert('Error');
      console.log(json);
      return;
    }

    if (!window.confirm(`${city}?`)) {
      return;
    }

    setCityTours([
      ...cityTours,
      {
        id: Math.random(),
        city: {
          en: city,
        },
        lat: Number(center.lat.toFixed(4)),
        lng: Number(center.lng.toFixed(4)),
        type: 'CITYTOUR',
        pointType: 'citytour',
      },
    ]);

    actions.cityTours.add({
      city: {
        en: city,
      },
      location: {
        lat: Number(center.lat.toFixed(4)),
        lng: Number(center.lng.toFixed(4)),
      },
    });
  };

  const _onSaveSpotClick = () => {
    const spot = getSelectedSpot();
    const newData = {
      name,
      from,
      slug,
      country,
      lat: newGPS.lat,
      lng: newGPS.lng,
    };

    setIsCreating(true);
    actions.spots
      .edit(spot, newData)
      .then(() => {
        setIsCreating(false);
      })
      .catch(error => {
        console.error(error);
        window.alert('Error editing!: ' + error.message);
      });

    setSpots(spots => spots.map(x => (x.id === spot.id ? { ...spot, ...newData } : x)));

    reset();
  };

  const onMainPhotoIdChange = ({ spotId, mainPhotoId }) => {
    setSpots(spots => {
      spots.find(x => x.id === spotId).mainPhotoId = mainPhotoId;
      return [...spots];
    });
    setSpotWithNoMainPhotoId(null);
  };

  const onIsTopPhotoChange = ({ spotId, isTop }) => {
    setSpots(spots => {
      spots.find(x => x.id === spotId).isTop = isTop;
      return [...spots];
    });
    setSpotWithNoIsTop(null);
  };

  const onDescriptionChange = ({ spotId, description }) => {
    setSpots(spots => {
      spots.find(x => x.id === spotId).description = description;
      return [...spots];
    });

    // TODO: Replace this with `null`
    setSpotWithNoDescription(
      spots.find(x => typeof x.description === 'undefined' || x.description === null)
    );
  };

  const _detectiveNeedsMoreInfo = async photo => {
    try {
      setPhotos(
        photos.filter(x => x.id !== photo.id).map(x => ({ ...x, isSelected: x.id === photo.id }))
      );

      _moveToNextPhoto(photo);

      await actions.photos.editById(photo.id, { detectiveNeedsMoreData: true });
    } catch (error) {
      window.alert('Error on detectiveNeedsMoreInfo: ' + error.message);
    }
  };

  const photo = getSelectedPhoto();
  const spot = getSelectedSpot();
  const isUnsplash = photo && photo.type === PHOTO_TYPE.UNSPLASH;

  let mapActions = [];

  if (photo && action === ACTIONS.NONE) {
    mapActions = [
      <button
        key={0}
        autoFocus
        data-action-key="1"
        className="approve"
        onClick={_onCreateNewSpotClick}
      >
        CREATE (1)
      </button>,
      <button
        key={1}
        data-action-key="2"
        className="approve"
        onClick={() => {
          setAction(ACTIONS.ASSIGN);
          setTimeout(() => {
            const visibleSpots = document.querySelectorAll('.Map .SpotPoint');

            if (visibleSpots.length === 1) {
              visibleSpots[0].click();
            }
          }, 10);
        }}
      >
        ASSIGN (2)
      </button>,
      <button
        key={2}
        data-action-key="3"
        className="reject"
        onClick={() => setAction(ACTIONS.REJECT)}
      >
        REJECT (3)
      </button>,
      <button
        key={3}
        data-action-key="4"
        className="reject"
        onClick={() => _detectiveNeedsMoreInfo(photo)}
      >
        NEEDS INFO (4)
      </button>,
    ];
  } else if (spot && ![ACTIONS.NONE, ACTIONS.EDIT, ACTIONS.CREATE.SPOT_FORM].includes(action)) {
    mapActions = [
      <button key={0} data-action-key="1" className="approve" onClick={_onEditSpotClick}>
        EDIT (1)
      </button>,
    ];
  } else {
    mapActions = [
      <button key={0} className="approve" onClick={_onCreateCityToutClick}>
        CREATE CITY TOUR
      </button>,
    ];
  }

  return (
    <div className="Moderation">
      <div className={`left ` + (action !== ACTIONS.NONE ? '--showingForm' : '')}>
        {!!photo && action === ACTIONS.NONE && (
          <Fragment>
            <img
              className="photo"
              src={isUnsplash ? photo.urls.regular : photo.url}
              data-id={photo.id}
              alt="Selected"
            />
            <div className="photo-description">
              {isUnsplash && (
                <Fragment>
                  <div>Description: {photo.description}</div>
                  <div>
                    Location: [{photo.location.name}] [{photo.location.title}]
                  </div>
                </Fragment>
              )}

              {!isUnsplash && <div>{photo.user.username}</div>}
            </div>
          </Fragment>
        )}

        {!!spot && action === ACTIONS.SHOW_SPOT && (
          <div className="slider">
            <div className="quantity">
              {spot.photosHydrated.length} unsplash photos, {spot.userPhotosHydrated.length} user
              photos
            </div>

            <Slider
              key={spot.id}
              photos={[
                ...spot.userPhotosHydrated.map(x => x.url),
                ...spot.photosHydrated.map(x => x.urls.regular),
              ]}
            />
          </div>
        )}

        {action === ACTIONS.CREATE.SET_GPS && (
          <div className="gps-set">
            <h1>Click on the map to set the new GPS position</h1>
            <div>
              GPS point: Lat {newGPS.lat} Lng {newGPS.lng}
            </div>
            <button autoFocus data-action-key="1" className="approve" onClick={_onGPSSet}>
              Done (1)
            </button>
          </div>
        )}

        {action === ACTIONS.CREATE.SPOT_FORM && (
          <div className="create-spot">
            <SpotForm
              spots={spots}
              onChange={_onSpotFormChange}
              onSubmit={() => {
                setTimeout(() => {
                  document.querySelector('button.approve').focus();
                }, 0);
              }}
              id={id}
              name={name}
              from={from}
              slug={slug}
              country={country}
              lat={newGPS.lat}
              lng={newGPS.lng}
            />

            {newGPS.lat && (
              <div>
                New GPS point: Lat {newGPS.lat} Lng {newGPS.lng}
              </div>
            )}

            {!!photo && (
              <div>
                <div>Description: {photo.description}</div>
                <div>
                  Location: [{photo.location.name}] [{photo.location.title}]
                </div>

                {photo.detective &&
                  photo.detective.map(item => (
                    <div key={item.whoId} style={{ marginTop: '8px' }}>
                      Name: {item.name}, from: {item.from} [DELETE]
                    </div>
                  ))}
              </div>
            )}

            <button style={{ marginTop: '16px' }} className="reject" onClick={() => reset()}>
              Cancel
            </button>

            <button
              autoFocus={!!name && !!from}
              className="approve"
              disabled={isCreating}
              onClick={_onCreateSpotClick}
            >
              {isCreating ? 'Creating...' : 'Create'}
            </button>
          </div>
        )}

        {action === ACTIONS.ASSIGN && (
          <div className="assign-form">
            <div>The photo is going to be assigned to:</div>

            {assignTo ? (
              <div>
                Spot Name: "{assignTo.name}" from "{assignTo.from}"
              </div>
            ) : (
              <div>Select a Spot</div>
            )}

            {assignTo && (
              <button
                data-action-key="1"
                disabled={isCreating}
                className="assign"
                onClick={_onAssign}
              >
                Yes, Assign it (1)
              </button>
            )}

            <button data-action-key="2" className="reject" onClick={() => reset()}>
              Cancel (2)
            </button>

            <br />
            <br />

            {assignTo && (
              <>
                {(assignTo.photosHydrated || []).map(x => (
                  <img
                    className="assign_photo"
                    key={x.id}
                    data-id={x.id}
                    src={x.urls.regular}
                    alt={assignTo.name}
                  />
                ))}

                {(assignTo.userPhotosHydrated || []).map(x => (
                  <img
                    className="assign_photo"
                    key={x.id}
                    data-id={x.id}
                    src={x.url}
                    alt={assignTo.name}
                  />
                ))}
              </>
            )}
          </div>
        )}

        {action === ACTIONS.REJECT && isUnsplash && (
          <div className="reject-form">
            <textarea value={cause} onChange={e => setCause(e.target.value)} placeholder="Cause" />
            <button className="reject" onClick={_onReject}>
              Reject
            </button>

            <br />
            <br />
            <br />
            <br />

            <button data-action-key="2" className="reject" onClick={_onRejectWrongGPS}>
              Wrong GPS (2)
            </button>
            <br />
            <br />
            <button data-action-key="3" className="reject" onClick={_onRejectBlah}>
              Blah (3)
            </button>
            <br />
            <br />
            <button className="reject" onClick={_onRejectDrone}>
              Drone
            </button>
          </div>
        )}

        {action === ACTIONS.REJECT && !isUnsplash && (
          <div className="reject-form">
            {REJECTION_CAUSES.map(x => (
              <button
                key={x.value}
                className={cause === x.value ? 'approve' : 'reject'}
                data-action-key={x['data-action-key']}
                onClick={() => setCause(x.value)}
              >
                {x.label}
                {x['data-action-key'] && ` (${x['data-action-key']})`}
              </button>
            ))}

            <br />
            <br />

            <button className="reject" data-action-key="Enter" onClick={_onReject}>
              Reject
            </button>
          </div>
        )}

        {action === ACTIONS.EDIT && (
          <div className="edit-spot-form">
            <SpotForm
              spots={spots}
              onChange={_onSpotFormChange}
              onSubmit={() => {
                setTimeout(() => {
                  document.querySelector('button.approve').focus();
                }, 0);
              }}
              id={id}
              name={name}
              from={from}
              slug={slug}
              country={country}
              lat={newGPS.lat}
              lng={newGPS.lng}
            />

            {newGPS.lat && (
              <div>
                New GPS point: Lat {newGPS.lat} Lng {newGPS.lng}
              </div>
            )}

            <button className="reject" onClick={reset}>
              Cancel
            </button>
            <button className="approve" onClick={_onSaveSpotClick}>
              Save
            </button>
          </div>
        )}
      </div>

      <div className="right">
        <div className="filters">
          <input type="checkbox" checked={showPendings} onChange={onPendingsFilterClick} /> Unsplash
          <input
            type="checkbox"
            checked={showPendingsFromUsers}
            onChange={onPendingsFromUsersFilterClick}
          />{' '}
          Users
          <input type="checkbox" checked={showPendings} onChange={onDetectiveFilterClick} />{' '}
          Detective
          {/* <input type="checkbox" checked={showRejecteds} onChange={onRejectedsFilterClick} />{' '}
          Rejected */}
          <input type="checkbox" checked={showSpots} onChange={onSpotsFilterClick} /> Spots
          {/* <input
            type="checkbox"
            checked={showNulls}
            onChange={() => setShowNulls(!showNulls)}
          />{' '}
          Nulls */}
          {' | '}
          <span onClick={() => setIsSettingSW(true)}>SW</span>:
          <input
            className="coor"
            type="text"
            onChange={e => onCoorsChange({ sw: { lat: e.target.value, lng: coors.sw.lng } })}
            value={coors.sw.lat}
          />
          <input
            className="coor"
            type="text"
            onChange={e => onCoorsChange({ sw: { lat: coors.sw.lat, lng: e.target.value } })}
            value={coors.sw.lng}
          />{' '}
          | <span onClick={() => setIsSettingNE(true)}>NE</span>:
          <input
            className="coor"
            type="text"
            onChange={e => onCoorsChange({ ne: { lat: +e.target.value, lng: coors.ne.lng } })}
            value={coors.ne.lat}
          />
          <input
            className="coor"
            type="text"
            onChange={e => onCoorsChange({ ne: { lat: coors.ne.lat, lng: +e.target.value } })}
            value={coors.ne.lng}
          />
        </div>

        <div className="map">
          <Map
            points={pointsToShow}
            center={center}
            zoom={zoom}
            onChange={_onMapChange}
            onClick={_onMapClick}
            actions={mapActions}
            onPointClick={_onPointClick}
            onPointHover={_onPointHover}
            onSpotClick={_onSpotClick}
          />
        </div>
      </div>

      {spotWithNoMainPhotoId && (
        <MainPhotoForm
          spot={spotWithNoMainPhotoId}
          onChange={onMainPhotoIdChange}
          onClose={() => setSpotWithNoMainPhotoId(null)}
        />
      )}

      {!spotWithNoMainPhotoId && spotWithNoIsTop && (
        <TopPhotoForm
          key={spotWithNoIsTop.id}
          spot={spotWithNoIsTop}
          onChange={onIsTopPhotoChange}
          onClose={() => setSpotWithNoIsTop(null)}
        />
      )}

      {!spotWithNoMainPhotoId && !spotWithNoIsTop && spotWithNoDescription && (
        <DescriptionForm
          key={spotWithNoDescription.id}
          spot={spotWithNoDescription}
          onChange={onDescriptionChange}
        />
      )}
    </div>
  );
};

export default Moderation;
