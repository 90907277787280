import React, { useState } from 'react';
// import Button from 'UI/Button';
import actions from 'actions';
import './styles.scss';

const ONE_DAY = 24 * 60 * 60 * 1000;

const Searcher = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [sortBy, setSortBy] = useState(null);
  const [filters, setFilters] = useState({
    id: {
      string: '',
    },
    username: {
      string: '',
    },
    email: {
      string: '',
      true: false,
      false: false,
    },
    subscriptionLevel: {
      string: '',
      true: false,
      false: false,
    },
    locale: {
      string: '',
      true: false,
      false: false,
    },
    expoNotiToken: {
      string: '',
      true: false,
      false: false,
    },
  });

  const init = async () => {
    setIsLoading(true);
    const { users } = await actions.searcher.getAllUsers();
    window.users = users;
    setUsers(users);
    setIsLoading(false);
  };

  const copy = e => {
    const range = document.createRange();
    range.selectNode(e.target);
    window.getSelection().removeAllRanges(); // clear current selection
    window.getSelection().addRange(range); // to select text
    document.execCommand('copy');
    window.getSelection().removeAllRanges(); // to deselect

    e.target.classList.add('clicked');

    setTimeout(() => {
      e.target.classList.remove('clicked');
    }, 300);
  };

  const openFirebase = user => {
    window.open(
      `https://console.firebase.google.com/u/0/project/mari-a5cc7/firestore/data/~2Fusers~2F${user.id}`
    );
  };

  const openRevenueCat = user => {
    window.open(`https://app.revenuecat.com/customers/11c246bc/${user.id}`);
  };

  const changeFilter = (filter, key, value) => {
    const newFilters = { ...filters };

    newFilters[filter][key] = value;

    setFilters(newFilters);
  };

  const updateSubscriptionLevel = async user => {
    const { user: newUser } = await actions.user.updateSubscription(user);

    const newUsers = [...users];

    Object.assign(
      newUsers.find(x => x.id === user.id),
      newUser
    );

    setUsers(newUsers);
  };

  const sort = key => {
    if (!sortBy || (sortBy && sortBy.key !== key)) {
      setSortBy({ key, order: 'desc' });
    } else if (sortBy.order === 'desc') {
      setSortBy({ key, order: 'asc' });
    } else {
      setSortBy(null);
    }
  };

  const usersToShow = users.filter(user => {
    // id
    if (filters.id.string) {
      if (!user.id.includes(filters.id.string)) {
        return false;
      }
    }

    // username
    if (filters.username.string) {
      if (!user.username.includes(filters.username.string)) {
        return false;
      }
    }

    // email
    if (filters.email.string) {
      if (!user.email || !user.email.includes(filters.email.string)) {
        return false;
      }
    }
    if (filters.email.true) {
      if (!user.email) {
        return false;
      }
    }
    if (filters.email.false) {
      if (user.email) {
        return false;
      }
    }

    // subscriptionLevel
    if (filters.subscriptionLevel.string) {
      if (
        !user.subscriptionLevel ||
        !user.subscriptionLevel.includes(filters.subscriptionLevel.string)
      ) {
        return false;
      }
    }
    if (filters.subscriptionLevel.true) {
      if (!user.subscriptionLevel) {
        return false;
      }
    }
    if (filters.subscriptionLevel.false) {
      if (user.subscriptionLevel) {
        return false;
      }
    }

    // locale
    if (filters.locale.string) {
      if (!user.locale || !user.locale.includes(filters.locale.string)) {
        return false;
      }
    }
    if (filters.locale.true) {
      if (!user.locale) {
        return false;
      }
    }
    if (filters.locale.false) {
      if (user.locale) {
        return false;
      }
    }

    // expoNotiToken
    if (filters.expoNotiToken.string) {
      if (!user.expoNotiToken || !user.expoNotiToken.includes(filters.expoNotiToken.string)) {
        return false;
      }
    }
    if (filters.expoNotiToken.true) {
      if (!user.expoNotiToken) {
        return false;
      }
    }
    if (filters.expoNotiToken.false) {
      if (user.expoNotiToken) {
        return false;
      }
    }

    return true;
  });

  if (sortBy) {
    usersToShow.sort((a, b) => {
      if (typeof a[sortBy.key] === 'number') {
        if (sortBy.order === 'asc') {
          return a[sortBy.key] - b[sortBy.key];
        } else {
          return b[sortBy.key] - a[sortBy.key];
        }
      } else {
        if (sortBy.order === 'asc') {
          return a[sortBy.key] < b[sortBy.key] ? 1 : -1;
        } else {
          return b[sortBy.key] < a[sortBy.key] ? 1 : -1;
        }
      }
    });
  }

  return (
    <div className="Searcher content">
      <h1>Searcher</h1>
      <h3>
        Total: {users.length} | Filtered: {usersToShow.length} (
        {((usersToShow.length / users.length) * 100).toFixed(2)}
        %)
      </h3>
      <br />
      <br />
      <div className="Searcher__filter">
        <div className="Searcher__filter-name">ID</div>
        <div className="Searcher__filter-value">
          <input
            type="text"
            onChange={e => changeFilter('id', 'string', e.target.value)}
            value={filters.id.string}
          />
        </div>
      </div>

      <div className="Searcher__filter">
        <div className="Searcher__filter-name">USERNAME</div>
        <div className="Searcher__filter-value">
          <input
            type="text"
            onChange={e => changeFilter('username', 'string', e.target.value)}
            value={filters.username.string}
          />
        </div>
      </div>

      <div className="Searcher__filter">
        <div className="Searcher__filter-name">EMAIL</div>
        <div className="Searcher__filter-value">
          <input
            type="text"
            onChange={e => changeFilter('email', 'string', e.target.value)}
            value={filters.email.string}
          />
        </div>

        <div className="Searcher__filter-bool">
          <input
            type="checkbox"
            checked={filters.email.true}
            onChange={e => changeFilter('email', 'true', e.target.checked)}
          />{' '}
          true?
        </div>

        <div className="Searcher__filter-bool">
          <input
            type="checkbox"
            checked={filters.email.false}
            onChange={e => changeFilter('email', 'false', e.target.checked)}
          />{' '}
          false?
        </div>
      </div>

      <div className="Searcher__filter">
        <div className="Searcher__filter-name">LEVEL</div>
        <div className="Searcher__filter-value">
          <input
            type="text"
            onChange={e => changeFilter('subscriptionLevel', 'string', e.target.value)}
            value={filters.subscriptionLevel.string}
          />
        </div>

        <div className="Searcher__filter-bool">
          <input
            type="checkbox"
            checked={filters.subscriptionLevel.true}
            onChange={e => changeFilter('subscriptionLevel', 'true', e.target.checked)}
          />{' '}
          true?
        </div>

        <div className="Searcher__filter-bool">
          <input
            type="checkbox"
            checked={filters.subscriptionLevel.false}
            onChange={e => changeFilter('subscriptionLevel', 'false', e.target.checked)}
          />{' '}
          false?
        </div>
      </div>

      <div className="Searcher__filter">
        <div className="Searcher__filter-name">LOCALE</div>
        <div className="Searcher__filter-value">
          <input
            type="text"
            onChange={e => changeFilter('locale', 'string', e.target.value)}
            value={filters.locale.string}
          />
        </div>

        <div className="Searcher__filter-bool">
          <input
            type="checkbox"
            checked={filters.locale.true}
            onChange={e => changeFilter('locale', 'true', e.target.checked)}
          />{' '}
          true?
        </div>

        <div className="Searcher__filter-bool">
          <input
            type="checkbox"
            checked={filters.locale.false}
            onChange={e => changeFilter('locale', 'false', e.target.checked)}
          />{' '}
          false?
        </div>
      </div>

      <div className="Searcher__filter">
        <div className="Searcher__filter-name">NOTI</div>
        <div className="Searcher__filter-value">
          <input
            type="text"
            onChange={e => changeFilter('expoNotiToken', 'string', e.target.value)}
            value={filters.expoNotiToken.string}
          />
        </div>

        <div className="Searcher__filter-bool">
          <input
            type="checkbox"
            checked={filters.expoNotiToken.true}
            onChange={e => changeFilter('expoNotiToken', 'true', e.target.checked)}
          />{' '}
          true?
        </div>

        <div className="Searcher__filter-bool">
          <input
            type="checkbox"
            checked={filters.expoNotiToken.false}
            onChange={e => changeFilter('expoNotiToken', 'false', e.target.checked)}
          />{' '}
          false?
        </div>
      </div>
      <br />
      <br />
      {!isLoading && users.length === 0 && <button onClick={init}>Init</button>}
      <br />
      <br />
      <div className="Searcher__table">
        <div className="Searcher__row">
          <div className="Searcher__col has-action" onClick={() => sort('id')}>
            ID {sortBy && sortBy.key === 'id' && sortBy.order}
          </div>
          <div className="Searcher__col">USERNAME</div>
          <div className="Searcher__col">EMAIL</div>
          <div className="Searcher__col has-action" onClick={() => sort('createdAt')}>
            CREATED {sortBy && sortBy.key === 'createdAt' && sortBy.order}
          </div>
          <div className="Searcher__col">LEVEL</div>
          <div className="Searcher__col">LOCALE</div>
          <div className="Searcher__col">NOTI</div>
          <div className="Searcher__col">ACTIONS</div>
        </div>

        {usersToShow.slice(0, 50).map(user => {
          return (
            <div className="Searcher__row" key={user.id}>
              <div className="Searcher__col has-action" onClick={copy}>
                {user.id}
              </div>
              <div className="Searcher__col has-action" onClick={copy}>
                {user.username}
              </div>
              <div className="Searcher__col has-action" onClick={copy}>
                {user.email}
              </div>
              <div className="Searcher__col has-action" onClick={copy}>
                {((Date.now() - user.createdAt) / ONE_DAY).toFixed(0)}
              </div>
              <div className="Searcher__col">{user.subscriptionLevel || ''}</div>
              <div className="Searcher__col">{user.locale || ''}</div>
              <div className="Searcher__col">{user.expoNotiToken ? '🟢' : '🔴'}</div>
              <div className="Searcher__col has-action">
                <span onClick={() => openFirebase(user)}>[🔥]</span>
                <span onClick={() => openRevenueCat(user)}>[🐈]</span>
                <span onClick={() => updateSubscriptionLevel(user)}>[🔃🐈]</span>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Searcher;
