import React, { Component } from 'react';
import actions from 'actions';
import './styles.scss';

const getRandom = array => {
  return array[Math.floor(Math.random() * array.length)];
};

class Marketing extends Component {
  state = {
    isLoading: false,
    unsplashUsersInApp: [],
    usersDone: [],
    usersToShow: [],

    // getMarketingFollowInstagram
    usersForInstagram: [],
  };

  init = async key => {
    this.setState({ isLoading: true });

    const { users: usersDone } = await actions.marketing.getBy({
      where: { [key]: true },
    });

    const { users: unsplashUsersInApp } = await actions.marketing.getUnsplashUsers();
    unsplashUsersInApp.sort((a, b) => b.photos.length - a.photos.length);

    this.setState(
      {
        usersDone: usersDone.map(x => ({ ...x, username: x.unsplashId })),
        unsplashUsersInApp,
        isLoading: false,
      },
      this._filterUsers
    );
  };

  initGetMarketingFollowInstagram = async () => {
    const { users } = await actions.user.getMarketingFollowInstagram();
    this.setState({ usersForInstagram: users });
  };

  _onTextClick = e => {
    e.target.select();
    document.execCommand('copy');
    window.open(`https://unsplash.com/@${this.state.usersToShow[0].username}`);
  };

  _onReportedClick = async key => {
    const user = this.state.usersToShow[0];

    try {
      await actions.marketing.markAs({ unsplashId: user.username, markAs: key });
      this.setState({ usersDone: [...this.state.usersDone, user] }, this._filterUsers);
    } catch (error) {
      console.error('$$ Error:', error);
      window.alert('Error');
    }
  };

  _filterUsers = () => {
    const { unsplashUsersInApp, usersDone } = this.state;

    const usersToShow = unsplashUsersInApp.filter(
      x => !usersDone.some(y => y.username === x.username)
    );

    this.setState({ usersToShow });
  };

  onReportDoneInstagram = async userId => {
    this.setState({
      usersForInstagram: this.state.usersForInstagram.filter((_x, index) => index !== 0),
    });

    await actions.user.setMarketingFollowInstagramAsDoneById(userId);
  };

  getText = () => {
    const user = this.state.usersToShow[0];
    const space = ['\n\n', '\n \n', '\n  \n'];

    const hello = [
      `Hi ${user.name.split(' ')[0]}! I've used some of your photos in my app.`,
      `Hello ${user.name.split(' ')[0]}! I've used some of your photos in my app.`,
    ];

    const donation = [
      'I want to thank you for sharing them on Unsplash! They are really useful for my project',
    ];

    const these = ['These are some of the them:', 'Here are some of the them:'];

    const photos = user.photos.slice(0, 3);
    const yourPhotosArray = [
      photos.map(photo => `- https://unsplash.com/photos/${photo}`).join('\n'),
      photos.map(photo => `* https://unsplash.com/photos/${photo}`).join('\n'),
      photos.map(photo => `# https://unsplash.com/photos/${photo}`).join('\n'),
      photos.map(photo => `> https://unsplash.com/photos/${photo}`).join('\n'),
      photos.map((photo, i) => `${i + 1}- https://unsplash.com/photos/${photo}`).join('\n'),
    ];

    const link = [
      `We've added a link to your Unsplash account on each of them!`,
      `We've added a link to your Unsplash account on each of them ;)`,
    ];

    const short = [
      'NoFilter App is an app for traveler and photographers.',
      'NoFilter App is an app for photographers and travelers.',
    ];

    const long = [
      'We help photographers and travelers to discover the best photo spots nearby, and all around the world.',
      'We help travelers and photographers to discover the best photo spots nearby, and all around the world.',
      'We help travelers and photographers to discover the best photo locations nearby, and all around the world.',
    ];

    const check = [
      'You can read more about the project in https://getnofilter.com',
      'You can read more about the app in https://getnofilter.com',
    ];

    const helpUs = [
      'PS.: Some of the photos may also be present in our Instagram account (there is always a mention to the photographer)',
      'PS.: Some of the photos may also be published in our Instagram account (there are always mentions to the photographers)',
    ];

    const me = ['Broda Noel, NoFilter founder', 'Broda Noel - NoFilter founder'];

    return (
      getRandom(hello) +
      getRandom(space) +
      getRandom(donation) +
      getRandom(space) +
      getRandom(these) +
      '\n' +
      getRandom(yourPhotosArray) +
      getRandom(space) +
      getRandom(link) +
      getRandom(space) +
      getRandom(short) +
      '\n' +
      getRandom(long) +
      getRandom(space) +
      getRandom(check) +
      getRandom(space) +
      getRandom(helpUs) +
      '\n' +
      'Bests!' +
      getRandom(space) +
      getRandom(me)
    );
  };

  render() {
    const { usersToShow, usersForInstagram } = this.state;

    return (
      <div className="Marketing">
        {usersForInstagram.length === 0 && (
          <button onClick={this.initGetMarketingFollowInstagram}>
            Init getMarketingFollowInstagram
          </button>
        )}

        {usersForInstagram.length > 0 &&
          [usersForInstagram[0]].map(user => (
            <div key={user.id}>
              <span
                onClick={() =>
                  window.open(
                    `https://console.firebase.google.com/project/mari-a5cc7/firestore/databases/-default-/data/~2Fusers~2F${user.id}`
                  )
                }
              >
                {user.username}
              </span>
              <br />
              <br />
              <span
                onClick={() =>
                  window.open(`https://instagram.com/${user.instagram.replace('@', '')}`)
                }
              >
                instagram.com/{user.instagram}
              </span>
              {' -or- '}
              <span onClick={() => window.open(user.instagram)}>{user.instagram}</span>
              <br />
              <br />
              <br />
              {user.userPhotos.map(userPhoto => (
                <img
                  key={userPhoto.id}
                  src={`https://storage.googleapis.com/mari-a5cc7.appspot.com/photos/small/${userPhoto.id}.jpg`}
                  alt="user content"
                  onClick={() =>
                    window.open(
                      `https://storage.googleapis.com/mari-a5cc7.appspot.com/photos/regular/${userPhoto.id}.jpg`
                    )
                  }
                  style={{
                    width: '300px',
                  }}
                />
              ))}
            </div>
          ))}

        {usersForInstagram.length > 0 && (
          <span onClick={() => this.onReportDoneInstagram(usersForInstagram[0].id)}>
            [MARK AS DONE]
          </span>
        )}

        {/* HERE, THE UNSPLASH LOGIC */}

        <br />
        <br />
        <br />

        {usersToShow.length === 0 && (
          <button onClick={() => this.init('contact-unsplash-2024')}>
            Init contact-unsplash-2024
          </button>
        )}

        {usersToShow.length > 0 && (
          <textarea readOnly onClick={this._onTextClick} value={this.getText()} />
        )}

        <div className="users">
          {usersToShow.map(user => (
            <div key={user.username} className="user">
              {user.name} - {user.photos.length} -{' '}
              <span onClick={() => this._onReportedClick('contact-unsplash-2024')}>
                [MARK AS DONE]
              </span>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default Marketing;
